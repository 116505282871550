<template>
    <div class="home relative w-full">
        <!-- header -->
        <div
            class="relative flex h-[80px] w-screen items-center justify-between px-8 py-1 lg:h-[100px] lg:px-[160px]"
        >
            <!-- left group -->
            <div class="flex items-center gap-[80px]">
                <!-- logo -->
                <img
                    src="assets/imgs/utils/logo/logo-text.png"
                    alt=""
                    class="w-[170px]"
                />
                <!-- terms & service & contact us -->
                <div class="hidden items-center gap-[60px] lg:flex">
                    <a
                        class="text-base font-bold text-[#fff]"
                        href="https://doc.tongifts.app/terms-of-use"
                        target="_blank"
                        >Terms</a
                    >
                    <a
                        class="text-base font-bold text-[#fff]"
                        href="https://doc.tongifts.app/visit-us"
                        target="_blank"
                        >Contact us</a
                    >
                    <a
                        class="text-base font-bold text-[#fff]"
                        href="assets/pdf/pitchdeck.pdf"
                        target="_blank"
                        >Whitepaper</a
                    >
                </div>
            </div>
            <!-- TG link -->
            <a
                target="_blank"
                href="https://t.me/GetTonGifts_Bot"
                class="hidden lg:flex lg:items-center lg:gap-3"
            >
                <img
                    src="assets/imgs/utils/socialMedia/telegram.svg"
                    alt="telegram"
                    class="h-10 w-10"
                />
                <span class="text-base font-bold text-[#fff]">START</span>
            </a>
            <!-- mobile dropdown -->
            <button
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                class="flex flex-col items-center justify-center gap-[3px] lg:hidden"
                type="button"
            >
                <div class="h-[3px] w-5 rounded-full bg-[#fff]"></div>
                <div class="h-[3px] w-5 rounded-full bg-[#fff]"></div>
                <div class="h-[3px] w-5 rounded-full bg-[#fff]"></div>
            </button>

            <!-- Dropdown menu -->
            <div
                id="dropdownHover"
                class="z-10 hidden w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700"
            >
                <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownHoverButton"
                >
                    <li>
                        <a
                            href="https://doc.tongifts.app/terms-of-use"
                            target="_blank"
                            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Terms</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://doc.tongifts.app/visit-us"
                            target="_blank"
                            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Contact us</a
                        >
                    </li>
                    <li>
                        <a
                            href="assets/pdf/pitchdeck.pdf"
                            target="_blank"
                            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Pitch Deck</a
                        >
                    </li>
                </ul>
            </div>
        </div>
        <!-- red bg -->
        <div
            class="absolute left-0 top-0 z-[-1] h-[790px] w-full bg-gradient-to-br from-[#00324a] to-[#094d6f] lg:h-[660px]"
        ></div>
        <!-- group1 wrapper -->
        <div
            class="mt-[30px] flex w-full flex-col lg:mt-[110px] lg:flex-row lg:justify-between"
        >
            <!-- what is ton drop wrapper -->
            <div
                class="relative flex w-full flex-col items-center gap-6 px-8 lg:max-w-[790px] lg:items-start lg:pl-[160px] lg:pr-[120px] xl:pr-[160px]"
            >
                <span
                    class="text-center text-[40px] font-bold text-[#fff] lg:text-left lg:text-[64px]"
                    >What is TonGifts?</span
                >
                <span class="text-center text-sm text-[#fff] lg:text-left"
                    >TonGifts is the fastest-growing interactive bot on
                    Telegram, offering you a new way to make gift-giving easier
                    and fun. No matter where you are, TonGifts will help you
                    share the joy with your friends and family by sending a
                    variety of gifts and props with just a few simple clicks,
                    making every moment special.
                </span>
                <!-- tg link -->
                <a target="_blank" href="https://t.me/GetTonGifts_Bot">
                    <div class="flex items-center gap-4">
                        <img
                            src="assets/imgs/utils/socialMedia/telegram.svg"
                            alt="telegram"
                            class="h-10 w-10"
                        />
                        <span class="text-xl font-bold text-[#fff]"
                            >Telegram App</span
                        >
                    </div>
                </a>
            </div>
            <!-- desktop red envelope img -->
            <a
                target="_blank"
                href="https://t.me/tongiftsnews"
                class="relative mr-[160px] mt-[30px] hidden w-[480px] min-w-[320px] justify-center lg:flex"
            >
                <img
                    src="assets/imgs/home/red_envelope.png"
                    alt=""
                    class="absolute left-0 top-0 w-[480px] min-w-[320px]"
                />
            </a>
            <!-- mobile red envelope img -->
            <a
                target="_blank"
                href="https://t.me/GetTonGifts_Bot"
                class="relative mt-[30px] flex w-full justify-center px-8 lg:hidden"
            >
                <img
                    src="assets/imgs/home/red_envelope.png"
                    alt=""
                    class="w-full max-w-[400px]"
                />
            </a>
        </div>
        <!-- group2 wrapper -->
        <div
            class="mt-[60px] flex w-full flex-col gap-10 px-8 lg:mt-[208px] lg:items-start lg:pl-[160px]"
        >
            <div
                class="flex w-full flex-col items-center gap-2 lg:flex-row lg:justify-start lg:gap-4"
            >
                <img
                    src="assets/imgs/home/icon_1.svg"
                    alt=""
                    class="h-12 w-12"
                />
                <span
                    class="text-center text-base font-bold text-[#0e293c] lg:text-2xl"
                    >make gift-giving easier and more fun</span
                >
            </div>
            <div
                class="flex w-full flex-col items-center gap-2 lg:flex-row lg:justify-start lg:gap-4"
            >
                <img
                    src="assets/imgs/home/icon_2.svg"
                    alt=""
                    class="h-12 w-12"
                />
                <span
                    class="text-center text-base font-bold text-[#0e293c] lg:text-2xl"
                    >share the joy with your friends
                </span>
            </div>
            <div
                class="flex w-full flex-col items-center gap-2 lg:flex-row lg:justify-start lg:gap-4"
            >
                <img
                    src="assets/imgs/home/icon_3.svg"
                    alt=""
                    class="h-12 w-12"
                />
                <span
                    class="text-center text-base font-bold text-[#0e293c] lg:text-2xl"
                    >just a few simple clicks</span
                >
            </div>
        </div>
        <!-- group3 wrapper -->
        <div
            class="mb-[80px] mt-[80px] w-full px-8 lg:mt-[160px] lg:px-[160px]"
        >
            <div
                class="relative flex w-full flex-col gap-5 lg:flex-row lg:gap-14"
            >
                <!-- desktop line -->
                <div
                    class="absolute left-0 top-[100px] z-[-1] hidden w-full border-2 border-dashed border-[#C92926] lg:block"
                ></div>
                <div
                    class="flex w-full flex-col items-center gap-3 lg:items-start"
                >
                    <span class="text-2xl font-bold text-[#000] lg:text-[32px]"
                        >Send Gift</span
                    >
                    <div class="w-full rounded-[16px] bg-[#FFEFEF] p-3">
                        <img
                            src="assets/imgs/home/step_1.png"
                            alt=""
                            class="w-full rounded-[12px]"
                        />
                    </div>
                </div>
                <div
                    class="flex w-full flex-col items-center gap-3 lg:items-start"
                >
                    <span class="text-2xl font-bold text-[#000] lg:text-[32px]"
                        >To Your Friends</span
                    >
                    <div class="w-full rounded-[16px] bg-[#FFEFEF] p-3">
                        <img
                            src="assets/imgs/home/step_2.png"
                            alt=""
                            class="w-full rounded-[12px]"
                        />
                    </div>
                </div>
                <div
                    class="flex w-full flex-col items-center gap-3 lg:items-start"
                >
                    <span class="text-2xl font-bold text-[#000] lg:text-[32px]"
                        >Received Gift</span
                    >
                    <div class="w-full rounded-[16px] bg-[#FFEFEF] p-3">
                        <img
                            src="assets/imgs/home/step_3.png"
                            alt=""
                            class="w-full rounded-[12px]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
};
</script>
