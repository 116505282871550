<template>
    <div class="error-page">
        <div class="dialog-box">
            <img
                src="assets/imgs/error/error_page_icon.png"
                alt="Error"
                class="error-icon"
            />
            <h1>{{ message }}</h1>
            <p>{{ description }}</p>
            <button @click="handleRedirect">Understand</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Error",
    data() {
        return {
            message: '',
            description: ''
        };
    },
    created() {
        const type = this.$route.query.type;
        const error = this.$route.query.error || "";
        switch(type) {
            case '1':
                this.message = 'This Twitter account is already in use';
                this.description = 'You cannot use this Twitter account to follow again.';
                // if (error == "")
                //     this.description = 'You cannot use this Twitter account to follow again.';
                // else
                //     this.description = error;
                break;
            case '2':
                this.message = 'Failed to follow Twitter';
                this.description = 'The failure may be related to your account settings.';
                // if (error == "")
                //     this.description = 'The failure may be related to your account settings.';
                // else
                //     this.description = error;
                break;
            default:
                this.message = 'Oops! Something went wrong.';
                this.description = "The failure may be related to your account settings.";
                // if (error == "")
                //     this.description = "The failure may be related to your account settings.";
                // else
                //     this.description = error;
                break;
        }
    },
    methods: {
        handleRedirect() {
            const link = 'https://twitter.com/' + (this.$route.query.link || '');
            window.location.href = link;
        }
    }
};
</script>

<style scoped>
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFC107;
}

.dialog-box {
    background-color: #FFFFFF;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 90%;
    max-width: 400px;
}

.error-icon {
    width: 80px;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    margin: 20px 0;
    color: #333;
    font-size: 24px;
}

p {
    color: #666;
    margin-bottom: 30px;
}

button {
    padding: 12px 24px;
    background-color: #D62F2A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #A82216;
}
</style>
